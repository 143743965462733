<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      庫存總覽
    </h3>
    <v-row align="center">
      <v-col cols="6" sm="4">
        <v-text-field
          outlined
          dense
          hide-details
          label="關鍵字"
          v-model="keyword"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="4">
        <v-checkbox
          v-model="showOnlyInStock"
          label="只顯示有庫存商品"
        ></v-checkbox>
      </v-col>
      <v-col cols="auto" class="d-none d-sm-block pa-0 ml-auto">
        <v-btn
          color="primary"
          @click="saveAsExcel"
          :disabled="filterProducts.length === 0"
        >
          匯出
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :items="filterProducts"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100],
            showCurrentPage: true,
            showFirstLastPage: true,
          }"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
// import util from "@/mixins/util";

export default {
  name: "Stock",
  // mixins: [util],
  components: {},
  data: () => ({
    // branch: "",
    stock: [],
    products: [],
    keyword: "",
    showOnlyInStock: true,
    branches: [
      { text: "文賢", value: "文賢" },
      { text: "崇善", value: "崇善" },
      { text: "奇美", value: "奇美" },
      { text: "友愛", value: "友愛" },
      { text: "總倉", value: "總倉" },
    ],
    headers: [
      { text: "商品名稱", value: "name" },
      { text: "商品售價", value: "price", align: "center", sortable: false },
      { text: "文賢", value: "文賢", align: "center", sortable: false },
      { text: "崇善", value: "崇善", align: "center", sortable: false },
      { text: "奇美", value: "奇美", align: "center", sortable: false },
      { text: "總倉", value: "總倉", align: "center" },
      { text: "總計", value: "totalAmount", align: "center", sortable: false },
    ],
    translatedHeader: {
      amount: "數量",
      branch: "分店",
      category: "商品類別",
      exchangeBranch: "兌換分店",
      expired: "有效日期",
      imageUrl: "圖片網址",
      isAvailable: "上架",
      isOnline: "線上兌換",
      name: "商品名稱",
      points: "點數",
      price: "售價",
      cost: "成本",
      productId: "商品id",
      store: "商店",
      type: "類型",
      _id: "id",
      regulatedAt: "最新校正時間",
      totalAmount: "總數",
    },
  }),
  computed: {
    _: () => _,
    filterProducts() {
      let products = this.products;
      if (this.keyword) {
        products = _.filter(products, (product) =>
          product.name.toUpperCase().includes(this.keyword.toUpperCase())
        );
      }

      if (this.showOnlyInStock) {
        products = _.filter(products, (product) => product.totalAmount > 0);
      }
      return products;
    },
  },
  async created() {
    this.$vloading.show();
    try {
      await this.load();
    } catch (error) {
      console.log(error);
    }
    this.$vloading.hide();
  },
  methods: {
    async load() {
      let { data: products } = await this.axios.get(`/product`);
      products = products.filter((o) => o.name);

      const { data: stocks } = await this.axios.get(`/stock`, {});
      this.stock = stocks;

      const branches = this.branches.map((branch) => branch.value);
      const stockAll = branches.reduce((preVal, branch) => {
        return {
          ...preVal,
          [branch]: _.mapKeys(
            stocks.filter((stock) => stock.branch === branch),
            "productId"
          ),
        };
      }, {});

      products = products.map((product) => {
        const amounts = branches.reduce((preVal, branch) => {
          return {
            ...preVal,
            [branch]: stockAll[branch][product._id]?.amount ?? 0,
          };
        }, {});

        return {
          productId: product._id,
          ...product,
          ...amounts,
          totalAmount: _.sum(Object.values(amounts)),
        };
      });
      this.products = products;
    },
    saveAsExcel() {
      const { sumByCategory, sumByType, lackPrice } = this.calculatedSum();
      if (lackPrice.size !== 0) {
        if (
          !confirm(
            `部分商品缺乏售價，計算總額時將視為 0，是否確認印出? \n缺乏售價之商品名稱為：${Array.from(
              lackPrice
            ).join(",")}`
          )
        )
          return;
      }

      const products = this.printProduct();
      this.exportToExcel(
        [
          { data: products, sheetName: "總計" },
          { data: sumByCategory, sheetName: "類別總計" },
          { data: sumByType, sheetName: "分類總計" },
        ],
        "庫存總額"
      );
    },
    calculatedSum() {
      const products = this.filterProducts;

      const types = _.uniq(
        this.products
          .filter((product) => product.type)
          .map((product) => product.type)
      );
      const category = _.uniq(
        this.products
          .filter((product) => product.category)
          .map((product) => product.category)
      );
      // let lackPrice = [];
      let lackPrice = new Set();

      const sumByType = types.map((type) => {
        const amounts = this.branches
          .map((branch) => branch.value)
          .reduce((pre, branch) => {
            return {
              ...pre,
              [branch]: _.sumBy(products, function (o) {
                if (o.type === type && _.isNumber(o.price)) {
                  return o.price * o[branch];
                } else if (o.type === type) {
                  // lackPrice = [...lackPrice, o.name];
                  lackPrice.add(o.name);
                }
                return 0;
              }),
            };
          }, {});

        return {
          商品類別: type,
          ...amounts,
        };
      });

      const sumByCategory = category.map((category) => {
        const amounts = this.branches
          .map((branch) => branch.value)
          .reduce((pre, branch) => {
            return {
              ...pre,
              [branch]: _.sumBy(products, function (o) {
                if (o.category === category && _.isNumber(o.price)) {
                  return o.price * o[branch];
                } else if (o.category === category) {
                  lackPrice.add(o.name);
                }
                return 0;
              }),
            };
          }, {});

        return {
          分類: category,
          ...amounts,
        };
      });
      return { sumByCategory, sumByType, lackPrice };
    },
    printProduct() {
      let products = this.filterProducts;
      const ignoreKeys = [
        "imageUrl",
        "imageURL",
        "productId",
        "_id",
        "store",
        "exchangeBranch",
        "expired",
        "isAvailable",
        "isOnline",
      ];
      const translatedHeader = this.translatedHeader;
      return products
        .map((product) => _.omit(product, ignoreKeys))
        .map((product) => {
          return Object.fromEntries(
            Object.entries(product).map(([key, value]) => [
              translatedHeader[key] || key,
              value,
            ])
          );
        });
    },
    exportToExcel(sheets, fileName) {
      const workBook = XLSX.utils.book_new();
      const today = dayjs().format("YYYY-MM-DD_HH-mm");

      sheets.forEach(({ data, sheetName }) => {
        const workSheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
      });
      XLSX.writeFile(workBook, `${fileName}_${today}.xlsx`);
    },
  },
};
</script>

<style scoped lang="scss"></style>
